import get from "lodash/get";

const supportedChains = ["homestead"];

const addresses = {
  ["homestead" as string]: {
    dsProxyRegistry: "0x4678f0a6958e4D2Bc4F1BAF7Bc52E8F3564f3fE4".toLowerCase(),
    proxyTarget: "0x71094715c5CD498a3D9dCbe128a93325cBeB6b6d".toLowerCase(),
    balanceSheet: "0x452467A37f7A0c1EA8432A52b8bbe3Cc31E9513b".toLowerCase(),
    fintroller: "0xEe46E4B672286392Fcb5308A58dCDb8f0bFcA8c3".toLowerCase(),
    oracle: "0xaFf0bb2f5C6E773ceE07E9465968f91Ca12A7EFe".toLowerCase(),
  },
  ["rinkeby" as string]: {
    dsProxyRegistry: "0xC72e21f4CD81E464398b7bd71f69187BDbC34a32".toLowerCase(),
    proxyTarget: "0x2b6e62577d2d09149964819df82540F9fF40b512".toLowerCase(),
    balanceSheet: "0x93A3677eab94ce90e07b4BC243DB2F8B1351c5B7".toLowerCase(),
    fintroller: "0xa5996a9D5Fc4141821DA5DF3A3EBDc7A7098AB86".toLowerCase(),
    oracle: "0xFE589Cad6d083e56b8850d3bFD3f4EeDdA975918".toLowerCase(),
  },
  ["matic" as string]: {
    dsProxyRegistry: "0xD1f063704b9AccEc409eB43Fc7978DFA0E353777".toLowerCase(),
    proxyTarget: "0xe58BA3155D8d84920295f1beE19C39eA184d0076".toLowerCase(),
    balanceSheet: "0xd2abC684c94603Cf3Eb72a0dAAAdE8943a754282".toLowerCase(),
    fintroller: "0x8396a70a550aebae178fc4b0065d9230b99f3eec".toLowerCase(),
    oracle: "0x7e40f333b23d7887d2c4d553f74dc463fa18c350".toLowerCase(),
  },
} as Record<string, Record<string, string>>;

export const hifiPoolAddresses = {
  homestead: [
    "0x3D015f32312c0cBBA9a494FD444CCEd959089491".toLowerCase(),
    // "0xf03Aa5d8C5Bb0664A370D90bf3e7cC3Ff3de50C8".toLowerCase(),
    // "0xe11C0E0238790752411E7A8BbBCe2234A01Bc552".toLowerCase(),
    "0x8e8c88322d155Cc8dabaf48f3d278B20bF6bb120".toLowerCase(),
  ],
  matic: [
    "0xA23A47595ce371452a5Da74eDEB134442682d069".toLowerCase(),
    "0x4e08ae7042a2a43f6adCDf9F79cd10f63944ed91".toLowerCase(),
  ],
  rinkeby: [
    "0x0fBd7a10F12A01473e8f82f652467F08f9561D39".toLowerCase(),
    "0x35464bF489569c40ca70d54137607D873E5D916f".toLowerCase(),
    "0x058Ea054500AF78eC258d7e91a52427377b271da".toLowerCase(),
    "0xA2c5e7Bbb24d40e2A93Db24e73C351cD517fd38B".toLowerCase(),
    "0x27420fdbf8541496e5b3d0cb34cce3e31fdf8a32".toLowerCase(),
    "0x4157acae9606416dd14650a6777b2ec905ca0545".toLowerCase(),
    "0xd6e03dbe305d774f11c7bbb5b525475664916a36".toLowerCase(),
    "0x75f44b31f46bec85d4735d30dff637382603e965".toLowerCase(),
    "0x023BfB897dbf31e60678A7E2C5E9828926790735".toLowerCase(),
    "0x5dcAc3CE91B3eD8C1Fe128df76332c247A0c7E0E".toLowerCase(),
    "0x32c46d59c98b4cefa4e85f4739560a96b998377e".toLowerCase(),
    "0x5d08044ea956e0e2e1e0929286db0d40567cbaff".toLowerCase(),
    "0x627324cF5D2749bB9E56fc5De4Db895E4beF82f6".toLowerCase(),
    "0x2e66B1D0cc866D6211E2B1dC31d65C87F352510C".toLowerCase(),
    "0xe6f69DD374861ef2083EB00437fAdc6bb17FD1E6".toLowerCase(),
    "0x260fD01f45aC55160d77e40fE6Ca0F3A75335127".toLowerCase(),
    "0xD987FFe2778cFEa4B87dbEC0A6Ae288bEffc9B93".toLowerCase(),
    "0x9Aff1CCF743b58cCFe635fB6d8d9A626AB6f4a43".toLowerCase(),
    "0xc99E134896119A1D345125C449d25bc0c3d8f83C".toLowerCase(),
    "0xA6FEB275830A542e07C5b191e9BC8Ba6A9326bB0".toLowerCase(),
    "0xa468a22Fd2f95070680E86cd99FA49067a309A26".toLowerCase(),
    "0xB767F7F47061AC36518a614D910e8e27e33aF087".toLowerCase(),
    "0xf42D71980AF5dECD47A83784513Eeab955D0a643".toLowerCase(),
    "0xAbD9d363e8F924EA7c5A83c6D90339BCA2CA104F".toLowerCase(),
    "0xe315E83829971f7DEc6CEF2389a93304B3Cc381A".toLowerCase(),
    "0x246E64A994B15f3C318ba515f82CDA483db91538".toLowerCase(),
    "0xeDB9478c34C3082B831A1F242e3C2322c134c3b1".toLowerCase(),
    "0x7aE3021d1809EAD5c11CeCffF4fbF4c99D4Df987".toLowerCase(),
    "0x21D10f399eF49d31644FB4a09691a1614aF84f78".toLowerCase(),
    "0xb5827238B447a0D0397B0656cFB81cb5D0e9cB18".toLowerCase(),
    "0xB98efbB2ad39D734cD281BcB22E1Ee525698868D".toLowerCase(),
    "0x2AFfb9618A73Bf4f588D85635917e64C0c9f10FC".toLowerCase(),
    "0x641c2Da69F04553DFC182346606214027AE5DfBF".toLowerCase(),
    "0xbda47a7375a319acd77ed53512160bf563878a80".toLowerCase(),
    "0x5ef75867f480646ef863f5995fb3642acb0e86ec".toLowerCase(),
    "0x02ea1da6e9bb159614c066390a21d526ecf330ef".toLowerCase(),
    "0xbcfee50cc70fb347186d4a881d1ce3d31a1277bb".toLowerCase(),
    "0x1f3dd1903c3ab8aa5dd4489ce2742f5ee0e0529f".toLowerCase(),
  ],
};

export const collateralAddresses = {
  homestead: [
    // WETH
    "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2".toLowerCase(),
    // VWMBp
    "0x858F0BDb905FDbB3E04d0430D9B4F9F48381f103".toLowerCase(),
    // SHEETp
    "0xc2bc2320D22D47D1e197E99D4a5dD3261ccf4A68".toLowerCase(),
    // CRE1p
    "0xC981D516a3fF48782dadb154eA8CFbD09BFCf923".toLowerCase(),
    // HC1
    "0xA4273c363DCE9544ec838b0043dDC2060CEa99d8".toLowerCase(),
    // RCC1
    "0xB8A6F4D5402E437E3A784113A762488cABCd72C4".toLowerCase(),
  ],
  matic: [
    "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619".toLowerCase(),
    "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6".toLowerCase(),
  ],
  rinkeby: [
    "0x40a2279Db3032ae70AAc81C9E106e3f97587E837".toLowerCase(),
    "0x79cD587E2caE0a0EaA321082a27e754d6f2DD6A9".toLowerCase(),
    "0xF9c2112E57E8B4c9d596986340a1315471f7d8da".toLowerCase(),
  ],
};

const permissiveTokens = {
  ["homestead" as string]: {
    // USDC
    ["0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48".toLowerCase()]: true,
    // VWMBp
    ["0x858F0BDb905FDbB3E04d0430D9B4F9F48381f103".toLowerCase()]: true,
    // SHEETp
    ["0xc2bc2320D22D47D1e197E99D4a5dD3261ccf4A68".toLowerCase()]: true,
    // CRE1p
    ["0xC981D516a3fF48782dadb154eA8CFbD09BFCf923".toLowerCase()]: true,
    // HC1
    ["0xA4273c363DCE9544ec838b0043dDC2060CEa99d8".toLowerCase()]: true,
    // RCC1
    ["0xB8A6F4D5402E437E3A784113A762488cABCd72C4".toLowerCase()]: true,
  },
  ["rinkeby" as string]: {
    // 2FA
    ["0x79cD587E2caE0a0EaA321082a27e754d6f2DD6A9".toLowerCase()]: true,
    // USDC
    ["0x7Bb35C860219DF484Ac38Be34028AbF5f4E72BeD".toLowerCase()]: true,
  },
  ["matic" as string]: {
    ["0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174".toLowerCase()]: true,
  },
};
export function isTokenPermissive(chainName: string, contractAddress: string): boolean {
  return !!permissiveTokens[chainName][contractAddress];
}

const weth = {
  ["homestead" as string]: {
    ["0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2".toLowerCase()]: true,
  },
  ["rinkeby" as string]: {
    ["0xc778417E063141139Fce010982780140Aa0cD5Ab".toLowerCase()]: true,
  },
  ["matic" as string]: {
    ["0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619".toLowerCase()]: true,
  },
};
export function isWeth(chainName: string, contractAddress: string): boolean {
  return !!weth[chainName][contractAddress];
}

export function getContractAddress(chainName: string, contractName: string): string {
  return get(addresses, [chainName, contractName], "");
}

export function getSupportedChains(): string[] {
  return supportedChains;
}

export function getContractName(chainName: string, contractAddress: string): string | undefined {
  const addrs = get(addresses, chainName);
  const contractName = Object.keys(addrs).find(sym => {
    const address = get(addrs, sym);
    return address.toLowerCase() === contractAddress.toLowerCase();
  }) as string | undefined;
  return contractName;
}
