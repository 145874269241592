import Bitcoin from "../Bitcoin";
import WrappedBitcoin from "../WrappedBitcoin";
import Ethereum from "../Ethereum";
import USDC from "../USDC";
import VWMBp from "../VWMBp";
import SHEETp from "../SHEETp";
import CRE1p from "../CRE1p";
import HC1 from "../HC1";
import RCC1 from "../RCC1";
import { TokenIconProps } from "./types";

const tokenMap = {
  BTC: Bitcoin,
  WBTC: WrappedBitcoin,
  ETH: Ethereum,
  WETH: Ethereum,
  USDC: USDC,
  VWMBp: VWMBp,
  SHEETp: SHEETp,
  CRE1p: CRE1p,
  HC1: HC1,
  RCC1: RCC1,
};

export default function TokenIcon({ symbol, style }: TokenIconProps): JSX.Element {
  const Icon = tokenMap[symbol];
  return Icon ? <Icon style={style} /> : <div style={{ ...style, lineHeight: "1em", width: "1em" }}>&nbsp;</div>;
}
